#franchise-list {
	padding: 2rem; }

table.list-franchises {
	width: 100%;
	border: 1px solid $lightergrey;
	border-collapse: collapse;
	th, td {
		padding: 0.5rem;
		text-align: left;
		border: 1px solid $lightergrey; } }

.locate-page {
	min-height: 100vh;
	@include container(100%);

	.inner {
		position: relative;
		padding-top: 9rem;
		@include susy-breakpoint($medium) {
			padding: 8rem; } }

	.page {
		min-height: 500px;
		max-width: 1000px;
		width: 100%;

		@include susy-breakpoint($small) {
			min-height: 650px; } } }

.locate-form {

	form {
		margin-bottom: 0; }

	h1 {
		font-size: 2rem;
		margin-bottom: 1rem;
		@include susy-breakpoint($small) {
			font-size: 3rem; } }

	.field {
		position: relative; }

	form#locate-form input#locate-input {
		margin-bottom: 0; }

	select {
		margin: 0; }

	.locate-fields {
		@include susy-breakpoint($small) {
			position: relative;
			display: flex;
			& > div {
				flex-grow: 1; }

			.country-select {
				margin-right: 1rem; } }


		& > div {
			margin-bottom: 0.5rem;
			@include susy-breakpoint($small) {
				margin-bottom: 0; } }

		input {
			width: 100%; }

		button {
			width: 100%; } }

	.geo-locator-wrap {
		padding-top: 1rem;
		clear: both;
		margin: 0 auto;
		max-width: 500px; }

	.geo-locator {

		p {
			text-align: center;
			font-size: 1.2rem; }

		#find-me {
			background: $green;
			// padding: 1rem
			color: $white;
			text-transform: uppercase;
			display: flex;
			font-size: 1rem;
			align-items: center;
			justify-content: center;
			margin-bottom: 0.5rem;
			min-width: 0;
			width: 100%;
			@include susy-breakpoint($small) {
				margin-bottom: 0;
				width: auto;
				margin-top: 1rem;
				// padding: 0.5rem 1rem
				font-size: 1.5rem; }

			i {
				padding-right: 0.5rem;
				font-size: 1.5rem; } } }

	.geo-status-wrap {
		padding: 0.5rem 0;
		text-align: center; }

	button#search {
		background: $orange;
		float: right; }

	ul.suggestions {
		list-style-type: none;
		margin: 0;
		float: left;
		width: 100%;
		padding: 0;
		background: $white;
		// border: 1px solid $lightergrey
		border-top: none;
		@include susy-breakpoint($small) {
			position: absolute;
			top: 100%;
			z-index: 99999;
			left: 0;
			right: 0; }

		li {
			cursor: pointer;
			padding: 0.5rem 1rem;
			font-size: 1rem;

			&:first-child {
				margin-top: 0.5rem; }

			&:last-child {
				margin-bottom: 0.5rem; }

			&:hover,
			&.selected {
				background: $lightergrey; } } }

	.locate-results {
		overflow-y: scroll;
		overflow-x: hidden;
		margin-top: 1rem;

		.result {
			padding: 1rem 0;
			font-size: 1.5rem;

			.result-title {
				a {
					color: $orange; } }

			a {
				display: inline-block;
				margin: 0.25rem 0;
				text-decoration: none;
				color: $black;

				&:hover {
					text-decoration: underline; } } } } }


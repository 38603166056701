section#franchise-opportunities {
	@apply min-h-screen {} }

#franchise {
	.page {
		padding: 2rem;
		@screen md {
			padding: 3rem; }
		background: white;
		min-height: 60vh; }

	.info-block {
		@apply .my-8 {}
		@screen md {
			@apply .flex .my-8 {}
			& > div {
				flex: 1; } }

		.map-container {
			@apply .pt-8 {}
			@screen md {
				@apply .pt-0 {} } }

		img {
			@apply self-start {} }

		#map {
			min-height: 50vh;
			max-height: 400px;
			@screen md {
				min-height: 400px;
				max-height: none; } } }

	.contact-details-container {
		@apply .pr-4 {}
		@screen md {
			@apply .pr-8 {} }

		a {
			color: $black; } }

	.contact-details {
		@apply .text-xl .pt-4 {}
		@screen md {
			@apply .text-2xl .pt-0 {} }
		label {
			color: $green; } }

	.size-title {
		@apply .text-2xl .uppercase .text-center {}
		color: $green; }

	.slick-slider {
		@apply .relative {} }

	.slick-next,
	.slick-prev {
		@apply .absolute .z-50 {}
		font-size: 5rem;
		top: 50%;
		transform: translateY(-50%);
		color: $green; }

	.slick-prev {
		left: 0; }

	.slick-next {
		right: 0; }

	.about {
		@apply .text-xl {} }

	.free-trial-cta {
		@apply .my-8 {} }

	.testimonials-module {
		@apply .flex-col {}
		background: rgba($green,0.3);
		.testimonial {
			@apply .italic .p-4 .text-center {}
			@screen md {
				@apply .p-8 {} }
			.message {
				@apply .text-2xl {}
				@screen md {
					@apply .text-3xl {} } }

			.author {
				@apply .text-xl .pt-2 .font-bold {}
				@screen md {
					@apply .text-2xl .pt-4 {} } } }

		.slick-track {
			@apply .flex .content-center {} }

		.slick-dots {
			@apply .flex .justify-center .p-0 .pt-4 .pb-8 .m-0 .list-none {}
			li {
				button {
					@apply .rounded-full .mx-2 .border-none .bg-transparent .cursor-pointer p-0 {}
					font-size: 0;
					background: rgba($green,0.5);
					overflow: hidden;
					text-indent: 100%;
					width: 13px;
					height: 13px; }
				&.slick-active {
					button {
						background: $green; } } } } }


	.photo-gallery-module {
		margin: 0 -1rem;
		.slide {
			width: auto;
			padding: 0 1rem;
			max-width: none;
			height: 100%; }

		.slick-prev {
			left: 2rem; }

		.slick-next {
			right: 2rem; } } }

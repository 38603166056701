//variables

// dont use bourbon box-sizing mixin with suzy
$susy: ( use-custom: ( box-sizing: false ));

$gutter: (gutters: 0.1, gutter-position: inside, use-custom: (box-sizing: false));
$splitgutter: (gutters: 0.3, gutter-position: split, use-custom: (box-sizing: false));
$minigutter: (gutters: 0.02, gutter-position: before, use-custom: (box-sizing: false));
$smallgutter: (gutters: 0.07, gutter-position: before, use-custom: (box-sizing: false));
$nogutter: (gutters: 0, use-custom: (box-sizing: false));


$black: #000;
$white: #FFF;
$grey: #111;
$lightgrey: rgba($black,0.01);
$lightergrey: #EBEBEB;
$footergrey: #9F9F9F;
$green: #A3CE67;
$darkergreen: #72A24C;
$purple: #BC7DB4;
$blue: #538DBA;
$orange: #F16950;
$yellow: #FFD54B;
$athomepurple: #7f9ed2;

$small: 700px;
$medium: 1200px;
$large: 1600px;





// rkt CSS util stuff
@mixin rkt($args...) {
	-content: "rkt #{$args}";
	&:before {
		content: "rkt #{$args}";
		width: 0;
		height: 0;
		position: absolute;
		overflow: hidden; } }


/* Slider */
.slick-slider {
	position: relative;
	display: block;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-webkit-touch-callout: none;
	-khtml-user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent; }

.slick-list {
	position: relative;
	display: block;
	overflow: hidden;
	margin: 0;
	padding: 0; }

.slick-list:focus {
	outline: none; }

.slick-list.dragging {
	cursor: pointer;
	cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0); }

.slick-track {
	position: relative;
	top: 0;
	left: 0;
	display: block; }

.slick-track:before,
.slick-track:after {
	display: table;
	content: ''; }

.slick-track:after {
	clear: both; }

.slick-loading .slick-track {
	visibility: hidden; }

.slick-slide {
	display: none;
	float: left;
	height: 100%;
	min-height: 1px; }

[dir='rtl'] .slick-slide {
	float: right; }

.slick-slide img {
	display: block; }

.slick-slide.slick-loading img {
	display: none; }

.slick-slide.dragging img {
	pointer-events: none; }

.slick-initialized .slick-slide {
	display: block; }

.slick-loading .slick-slide {
	visibility: hidden; }

.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent; }

.slick-arrow.slick-hidden {
	display: none; }


.slick-initialized {
	visibility: visible; }


.mfp-bg {
	z-index: 9999042; }

.mfp-wrap {
	z-index: 9999043; }

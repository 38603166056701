.landing-popup {
	@apply fixed inset-0 flex justify-center items-center {}
	z-index: 99999;
	background: rgba(black,0.5);

	.popup-image-container {
		img {
			max-width: 400px; } }
	.close-btn {
		@apply text-white text-center uppercase underline cursor-pointer {}
		font-size: 1.4rem; } }

//nav

.header-bar {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	height: 4.5rem;
	background: rgba($white,0.9);
	opacity: 0;
	z-index: 9999;
	transition: opacity 0.3s ease;

	@include susy-breakpoint($small) {
		height: 6rem; }

	&.scrolled {
		opacity: 1; } }

.free-trial-btn,
.faq-btn {
	position: fixed;
	bottom: 0;
	text-align: center;
	display: inline-block;
	background: $green;
	padding: 1rem 1rem;
	text-transform: uppercase;
	color: $white;
	z-index: 9999;
	font-size: 1.2rem;
	text-decoration: none;

	@include susy-breakpoint($medium) {
		bottom: auto;
		top: 1.15rem;
		text-align: right;
		font-size: 1.5rem;
		padding: 1rem 2rem; } }

.free-trial-btn {
	right: 0;
	left: 0;
	display: none;
	@include susy-breakpoint($medium) {
		display: block;
		right: 13rem;
		left: auto; } }

.faq-btn {
	background: #bc7cb4;
	padding: 0.5rem 1rem 0.7rem;
	top: 1rem;
	left: 1rem;
	bottom: auto;
	z-index: 99;
	@include susy-breakpoint($medium) {
		display: block;
		left: 1rem;
		padding: 1rem 2rem; } }

body.ch-en,
body.ch-fr,
body.ch-de {
	.free-trial-btn {
		@include susy-breakpoint($medium) {
			right: 16rem; } } }

.current-locale.fixed {
	color: $white;
	text-decoration: none;
	font-size: 1rem;
	margin-top: 1.5rem;
	display: block;
	position: fixed;
	top: 0rem;
	right: 6.5rem;
	z-index: 99999;
	margin-right: 0.5rem;

	@include susy-breakpoint($small) {
		font-size: 1.5rem;
		margin-top: 0.5rem;
		top: 1.5rem;
		right: 8.5rem;
		margin-right: 0.5rem; }

	a {
		cursor: pointer; }

	img {
		max-width: 25px;
		// margin-right: 0.75rem
		display: inline-block;
		vertical-align: text-bottom;
		box-shadow: 0 0 5px 1px rgba($black,0.1);

		@include susy-breakpoint($small) {
			max-width: 40px;
			// margin-right: 0.75rem
			vertical-align: text-bottom; } }

	.flag-icon {
		display: inline-block; }

	.ch-current-locale {
		display: inline-block;
		font-size: 0.9rem;
		color: $black;
		text-transform: uppercase;
		height: 30px;
		line-height: 30px;
		vertical-align: top;
		position: relative;
		cursor: pointer; }

	i.fa {
		font-size: 1rem;
		padding: 0 0.5rem; }

	.ch-locales-drop {
		position: absolute;
		top: 30px;
		line-height: 20px;
		left: 0;
		display: inline-block;
		opacity: 0;
		pointer-events: none;
		transition: opacity 0.3s ease;

		&.active {
			opacity: 1;
			pointer-events: all; }

		a.ch-locale {
			display: block;
			text-decoration: none;
			opacity: 0.3;
			font-size: 0.9rem;
			color: $black;
			text-transform: uppercase;

			&:hover {
				opacity: 1; } } } }

.navToggle {
	position: fixed;
	top: 1rem;
	right: 1rem;
	z-index: 999999;
	cursor: pointer;
	@include susy-breakpoint($small) {
		top: 1.2rem;
		right: 2rem; }

	i.material-icons {
		font-size: 2rem;
		@include susy-breakpoint($small) {
			font-size: 3rem; } }

	i.open {
		color: $black; }

	i.close {
		color: $white; } }

.locate-btn {
	position: fixed;
	top: 1rem;
	right: 4rem;
	z-index: 999999;
	cursor: pointer;
	color: $black;
	@include susy-breakpoint($small) {
		top: 1.1rem;
		right: 5.5rem; }

	i.material-icons {
		font-size: 2rem;
		@include susy-breakpoint($small) {
			font-size: 3rem; } }

	&.active {
		color: $white; } }

nav {
	display: block;
	position: fixed;
	width: 100vw;
	right: -100vw;
	top: 0;
	bottom: 0;
	background: $black;
	z-index: 9999;
	padding: 2rem;
	transition: right 0.3s cubic-bezier(0.77, 0, 0.175, 1);
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch;
	padding-bottom: 4rem;

	@include susy-breakpoint($medium) {
		width: 50vw;
		right: -50vw;
		padding-bottom: 0;
		padding: 6rem; }

	&.active {
		right: 0; }


	img.nav-logo {
		margin-top: 1rem;
		max-width: 130px;
		@include susy-breakpoint($small) {
			max-width: 200px; } }

	ul {
		margin: 1rem 0;
		padding: 0;
		list-style-type: none;

		@include susy-breakpoint($small) {
			padding: 2rem 0; }

		li {
			padding: 1.3rem 0;
			margin: 0;
			border-bottom: 1px solid rgba($white, 0.2);
			@include susy-breakpoint($small) {
				padding: 1.5rem 0;
				border-bottom: 2px solid rgba($white, 0.2); } }

		a {
			color: $white;
			text-decoration: none;
			font-size: 1.3rem;
			@include susy-breakpoint($small) {
				font-size: 2rem; }
			@include susy-breakpoint($large) {
				font-size: 3rem; } } }

	ul.social-icons {
		margin-top: 0;
		padding-top: 0;
		@include susy-breakpoint($small) {
			margin-bottom: 0;
			padding-top: 0.5rem; }

		li {
			display: inline-block;
			border-bottom: none;
			padding: 0;
			padding-right: 1.5rem;
			@include susy-breakpoint($small) {
				padding-right: 2rem; } }

		a {
			font-size: 2rem;
			@include susy-breakpoint($small) {
				font-size: 3rem; } } }

	ul.eoy-logo {
		margin: 0;
		margin-top: 1.5rem;
		@include susy-breakpoint($small) {
			margin-top: 0; }

		li {
			padding: 0;
			border-bottom: none;

			img {
				max-width: 150px;
				@include susy-breakpoint($small) {
					max-width: 250px; } } } } }

form {
	position: relative;
	margin: 1rem 0;
	@include container(100%);

	@include susy-breakpoint($small) {
		margin: 3rem 0; }

	.form-label {
		font-size: 1.5rem; }

	.field {
		position: relative;
		@include span(1 of 1);
		@include susy-breakpoint($small) {
			&.first {
				@include span(1 of 2 $smallgutter first); }
			&.last {
				@include span(1 of 2 $smallgutter last); }
			&.full {
				@include span(1 of 1); } }

		&.checkbox {
			font-size: 1.2rem;
			padding: 0.5rem;
			display: inline-block;
			width: auto;

			&.required::after {
				display: none; }

			input {
				position: absolute;
				top: 0.8rem; }

			label {
				margin-left: 2rem;
				line-height: normal;
				display: block;

				p {
					margin: 0; } } }

		&.required::after {
			content: "*";
			position: absolute;
			top: 0.25rem;
			right: 0.5rem;
			font-size: 1.3rem; }

		&.hidden {
			display: none; } }

	input:not([type="checkbox"]),
	select,
	textarea {
		border: none;
		background: $lightergrey;
		padding: 1rem;
		width: 100%;
		margin-bottom: 1rem;
		appearance: none;
		border-radius: 0;
		font-size: 1.5rem;
		line-height: normal;

		&::placeholder {
			color: $black; } }

	button {
		background: $green;
		border: none;
		color: $white;
		text-transform: uppercase;
		padding: 1rem 2rem;
		font-size: 1.5rem;
		cursor: pointer;
		width: 100%;
		height: 100%;
		border-radius: 0;

		@include susy-breakpoint($small) {
			width: auto; } }

	.parsley-errors-list {
		display: none; }

	.parsley-error:not(.checkbox) {
		box-shadow: inset 0 0 0 2px red; }

	.parsley-error.checkbox {
		color: red; }

	.message {
		font-size: 1.5rem; }

	.submitting,
	.thankyou {
		display: none; }

	&.submitting {
		.submit-button {
			display: none; }
		.submitting {
			display: block; } }

	&.success {
		.field {
			display: none; }
		.thankyou {
			display: block; } }

	.required-note {
		float: right;
		margin-top: 1rem; }

	.form-total-button {
		@include susy-breakpoint($small) {
			@apply flex justify-center items-center {} } } }


#cardElement {
	background: $lightergrey;
	padding: 1rem; }

.barba-container#offer {

	section {
		padding-top: 4rem;
		@screen md {
			padding-top: 0; } }

	h3 {
		font-weight: bold;
		@apply leading-tight {} }

	.page-title {
		font-family: 'SignPainter Medium';
		max-width: 1000px;
		margin: 0 auto;
		padding: 2rem 1rem;
		text-align: center;
		background: $blue;
		color: $white;
		font-size: 3rem;
		@screen md {
			@apply leading-none {}
			font-size: 6rem; } }
	.price-badge {
		max-width: 120px;
		margin: 1rem auto 0;
		@screen md {
			max-width: 200px;
			float: right; }

		&::after {
			content: "";
			display: block;
			clear: both; } }

	.slick-slider {
		@apply .relative my-8 {}

		// &::before
		// 	position: absolute
		// 	width: 20%
		// 	height: 100%
		// 	content: ""
		// 	top: 0
		// 	left: 0
		// 	background: linear-gradient(to right, $black, rgba($black, 0))
		// 	z-index: 20

		// &::after
		// 	position: absolute
		// 	width: 20%
		// 	height: 100%
		// 	content: ""
		// 	top: 0
		// 	right: 0
		// 	background: linear-gradient(to left, $black, rgba($black, 0))
 }		// 	z-index: 20

	.slick-next,
	.slick-prev {
		@apply .absolute .z-50 .cursor-pointer {}
		font-size: 5rem;
		top: 50%;
		transform: translateY(-50%);
		color: inherit; }

	.slick-prev {
		left: 1rem; }

	.slick-next {
		right: 1rem; }

	.slide {
		margin: 0 0.5rem; }


	#formFieldsWrapper {
		transition: opacity 0.3s ease;

		input,
		select {
			transition: opacity 0.3s ease; }

		&.wait {
			@apply opacity-50 {} }

		&.locked {
			@apply pointer-events-none {}
			input,
			select {
				@apply bg-transparent cursor-not-allowed opacity-50 p-0 {} }
			.field::after,
			.required-note {
				@apply hidden {} } } }

	#paymentWrapper {
		transition: opacity 0.3s ease;
		&.wait {
			@apply opacity-50 {} }


		.payment-field-labels {
			@screen md {
				@apply flex justify-between {} } } } }

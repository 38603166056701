#landingpage {
	.intro,
	.slideshow,
	.slideshow .slide,
	.intro .inner {
		@include susy-breakpoint($medium) {
			height: 70vh;
			min-height: 500px; } }

	h1.slogan {
		font-size: 2rem;
		@include susy-breakpoint($small) {
			font-size: 3rem; }
		@include susy-breakpoint($medium) {
			font-size: 4rem; } }

	a.free-trial-btn {
		@include susy-breakpoint($small) {
			position: relative;
			left: auto;
			right: auto;
			margin-left: 1rem;
			top: auto;
			font-size: 2rem;
			padding: 1rem 2rem 1.2rem;
			left: 50%;
			transform: translateX(-50%);
			margin-top: 2.5rem; }

		@include susy-breakpoint($medium) {
			left: 1rem;
			transform: translateX(0);
			margin-top: 1rem;

			&.fixed {
				position: fixed;
				top: 0;
				right: 1rem;
				left: auto; } } }


	footer {
		@include susy-breakpoint($small) {
			padding: 4rem 2rem 4rem;

			.footer-logo {
				max-width: 130px; }

			.footer-ey-logo {
				max-width: 100px; } } } }

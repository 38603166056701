section#gallery-page {

	.page {
		padding: 0rem 0rem 4rem;
		@include susy-breakpoint($small) {
			padding: 0rem 7rem 4rem; } }

	.inner {
		padding: 4rem 1rem 2rem; }

	.copy {
		margin-bottom: 2rem;
		text-align: center; }

	.gallery-header {
		position: relative;
		height: 180px;
		margin-bottom: 100px;
		background-size: cover;

		@include susy-breakpoint($small) {
			height: 250px;
			margin-bottom: 200px; }

		.logo {
			position: absolute;
			left: 50%;
			bottom: 0;
			transform: translate(-50%, 50%);
			z-index: 1;
			width: 160px;
			height: 160px;

			@include susy-breakpoint($small) {
				width: 260px;
				height: 260px; } }

		.gallery-nav {
			position: absolute;
			bottom: -140px;
			left: 0;
			width: 100%;
			z-index: 1;

			@include susy-breakpoint($small) {
				bottom: -230px; }

			ul {
				width: 100%;
				text-align: center;
				margin: 0;
				padding: 0; }

			li {
				margin: 0;
				padding: 0;
				display: inline-block;
				list-style-type: none;

				&.active a {
					opacity: 1; } }

			a {
				font-size: 1rem;
				text-decoration: none;
				color: $black;
				padding: 0 1rem;
				text-transform: uppercase;
				opacity: 0.3;

				@include susy-breakpoint($small) {
					font-size: 2rem;
					padding: 0 2rem; }

				&:hover {
					opacity: 1; } } } }

	.page.flowers {
		@include container(100%);

		.gallery-slideshow {
			position: relative;

			i {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
				font-size: 6rem;
				z-index: 999;
				color: $black;
				cursor: pointer;
				visibility: hidden;

				@include susy-breakpoint($small) {
					visibility: visible; }

				&.slick-prev {
					left: 2rem; }

				&.slick-next {
					right: 2rem; } }

			@include susy-breakpoint($small) {

				&::before {
					position: absolute;
					width: 20%;
					height: 100%;
					content: "";
					top: 0;
					left: 0;
					background: linear-gradient(to right, $white, rgba($white, 0));
					z-index: 998; }

				&::after {
					position: absolute;
					width: 20%;
					height: 100%;
					content: "";
					top: 0;
					right: 0;
					background: linear-gradient(to left, $white, rgba($white, 0));
					z-index: 998; } }

			@include susy-breakpoint($large) {
				&::before,
				&::after {
					background: none; } } } }

	.gallery {
		@include container(100%);

		.image {
			display: block;
			position: relative;
			@include span(1 of 2 $nogutter);

			@include susy-breakpoint($small) {
				@include span(1 of 3 $nogutter); }

			img {
				padding: 3px; }

			.info {
				opacity: 0;
				transition: all 0.3s ease;
				position: absolute;
				left: 3px;
				top: 3px;
				right: 3px;
				bottom: 3px;
				background: rgba($black,0.8);
				display: flex;
				align-items: center;

				&:hover {
					opacity: 1; }

				.info-content {
					width: 100%;
					text-align: center;
					text-decoration: none;
					color: $white;
					text-transform: uppercase; }

				.info-sub-title {
					font-weight: bold; } } } } }

//typography

h1,h2 {
	letter-spacing: -0.07rem; }

h1 {
	font-size: 3rem;
	font-weight: 100;
	margin: 0;

	@include susy-breakpoint($small) {
		font-size: 3.5rem; }

	@include susy-breakpoint($medium) {
		font-size: 4rem; }

	@include susy-breakpoint($large) {
		font-size: 5.5rem; } }

h2 {
	font-size: 2.5rem;
	font-weight: 400;
	margin: 0;

	@include susy-breakpoint($small) {
		font-size: 4rem; } }

h3 {
	font-size: 2rem;
	font-weight: 400;
	margin: 0;

	@include susy-breakpoint($small) {
		font-size: 3rem; } }

h4 {
	font-size: 1.5rem; }

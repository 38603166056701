//base html stuff
html,
body {
	background: white;
	height: 100%;
	min-height: 100%;
	font-smoothing: antialiased;
	-webkit-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	color: $grey;
	font-size: 90%;
	line-height: 1.2;

	@include susy-breakpoint($small) {
		font-size: 90%; } }

* {
	box-sizing: border-box;

	&:focus {
		outline: none; } }

.material-icons {
	line-height: inherit; }

img {
	display: block;
	width: 100%; }

.clearfix:after {
	content: "";
	display: table;
	clear: both; }

.intro .logo,
.loading .logo {
	width: 160px;
	height: 160px;
	background: $white;
	position: absolute;
	top: 35vh;
	left: 50%;
	margin-left: -80px;
	margin-top: -80px;
	transition: opacity 0.3s ease 0.15s;

	@include susy-breakpoint($medium) {
		top: 50%;
		margin-left: -100px;
		margin-top: -100px;
		width: 200px;
		height: 200px; }

	@include susy-breakpoint($large) {
		margin-left: -130px;
		margin-top: -130px;
		width: 260px;
		height: 260px; }

	&.active {
		opacity: 0; } }

.loading {
	transition: visibility 0s ease 0s;

	.logo {
		background: none;
		z-index: 9999999999999;
		fill: none;
		transition: opacity 0.3s ease; }

	&.loaded {
		visibility: hidden;
		transition: visibility 0s ease 1s;

		.logo {
			opacity: 0; } } }

.intro {
	.logo {
		z-index: 99; } }

#preload-bar {
	width: 100%;
	height: 5px;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999999999;
	background: white;

	svg {
		height: 5px; }

	path {
		stroke: grey;
		transition: all 0.3s ease; } }

#preload-overlay {
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 99999999;
	background-color: white; }

.barba-container {
	overflow: hidden; }


.fullheight {
	min-height: 100vh; }

.slideshow {
	position: absolute;
	width: 100vw;
	height: 100vh;

	.slide {
		height: 100vh;
		background-size: cover;
		background-position: center; } }

.card {
	background: $white;
	opacity: 0.9;
	padding: 2rem 2rem;
	margin: 0;
	position: relative;
	@include susy-breakpoint($medium) {
		margin: 0 2rem;
		padding: 3rem 7rem;
		@include span(3 of 5 $nogutter);

		&.right {
			@include span(isolate 3 at 3 of 5 $nogutter); } }

	.card-title {
		text-align: center;
		@include susy-breakpoint($small) {
			text-align: left; } }

	a {
		color: $white;
		display: inline-block;
		background: $black;
		padding: 0.5rem 1rem;
		text-decoration: none; } }


.page {
	@include container(1000px);
	background: rgba($white, 0.95);
	padding: 2rem 2rem;
	position: relative;
	@include susy-breakpoint($medium) {
		padding: 3rem 7rem; }

	h1 {
		font-size: 2.5rem;
		font-weight: 400;
		margin: 0;

		@include susy-breakpoint($small) {
			font-size: 4rem; } } }

.single-page {
	background-attachment: fixed; }

.page-close {
	position: fixed;
	top: 1rem;
	left: 1rem;
	z-index: 999999;
	cursor: pointer;
	color: $black;
	@include susy-breakpoint($small) {
		top: 1.2rem;
		left: 2rem; }

	i.material-icons {
		font-size: 2rem;
		@include susy-breakpoint($small) {
			font-size: 3rem; } } }


.copy {
	font-size: 1.3rem;
	line-height: 1.5;

	@include susy-breakpoint($small) {
		font-size: 1.5rem;
		line-height: 1.7; }

	&.pad {
		padding: 1rem 0;
		@include susy-breakpoint($small) {
			padding: 2rem 0; } }

	a:not(.btn) {
		color: $green; } }

section {
	background-size: cover;
	background-position: center;

	.inner {
		position: relative;
		padding: 4rem 1rem 2rem;
		position: relative;
		@include container(1400px);

		@include susy-breakpoint($small) {
			padding: 8rem 1rem; }

		&.wide {
			@include container(1600px); } }

	.section-title {
		padding: 0;
		text-align: center;
		position: relative;

		@include susy-breakpoint($small) {
			display: block; } }

	.section-sub_title {
		text-align: center;
		font-size: 1.5rem;
		@include susy-breakpoint($small) {
			font-size: 2rem; } } }

#locale-switcher {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba($black,0.8);
	z-index: 99999999999;
	display: none;

	&.ch {
		background: none;
		background-position: center;
		background-size: cover;
		display: block;
		z-index: 9999;

		.top-deck {
			background: rgba($white, 0.9);
			padding: 3rem 2rem 2rem; }

		ul {
			text-align: center;
			background: none;
			@include susy-breakpoint($small) {
				height: 600px; }

			.logo {
				max-width: 120px;
				max-height: 120px;
				@include susy-breakpoint($small) {
					max-width: 160px;
					max-height: 160px; } }

			li {
				text-align: left;
				background: rgba($black, 0.9); } } }

	.bg.close {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0; }

	a.close {
		cursor: pointer; }

	ul {
		position: fixed;
		top: 50%;
		left: 10%;
		width: 80%;
		height: 80vh;
		background: $black;
		overflow-y: scroll;
		list-style: none;
		margin: 0;
		padding: 0rem 0;
		margin-top: -40vh;
		font-size: 1rem;
		z-index: 999999999999;

		@include susy-breakpoint($small) {
			width: 500px;
			height: 500px;
			left: 50%;
			margin-left: -250px;
			margin-top: -250px;
			font-size: 1.5rem; }

		li {
			padding: 1rem 1rem;
			margin: 0;
			border-bottom: 1px solid rgba($white, 0.2);
			@include susy-breakpoint($small) {
				padding: 1.5rem 2rem;
				border-bottom: 2px solid rgba($white, 0.2); }

			&:last-child {
				border-bottom: none; }

			&.ch {
				display: none; } }

		img {
			max-width: 25px;
			margin-right: 0.5rem;
			display: inline-block;
			vertical-align: text-bottom;

			@include susy-breakpoint($small) {
				max-width: 40px;
				margin-right: 1rem;
				vertical-align: text-bottom; } }

		a {
			text-decoration: none;
			color: $white; } } }


.branding-bar {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	background: $white;
	text-align: center;
	text-transform: uppercase;
	padding: 4rem 2rem 1rem;
	font-size: 1.2rem;
	@include susy-breakpoint($small) {
		padding: 1.5rem 2rem 1.5rem; }
	@include susy-breakpoint($medium) {
		font-size: 1.5rem;
		padding: 2rem 3rem; }
	@include susy-breakpoint($large) {
		font-size: 2rem;
		padding: 1.8rem 3rem; }

	span {
		display: block;
		@include susy-breakpoint($medium) {
			display: inline-block;
			padding: 0 0.5rem; } }

	.sub-slogan {
		color: $green; } }

p.error {
	text-align: center;
	color: red;
	font-weight: bold;
	font-size: 1rem; }

.btn {
	text-align: center;
	display: inline-block;
	background: $green;
	padding: 1rem 1rem;
	text-transform: uppercase;
	color: $white;
	z-index: 9999;
	min-width: 300px;
	font-size: 1.2rem;
	text-decoration: none;
	cursor: pointer;
	border: none;

	@include susy-breakpoint($medium) {
		font-size: 1.5rem;
		padding: 1rem 2rem; }

	&.full {
		display: block;
		min-width: 0;
		width: 100%; }

	&.gap-bot {
		margin-bottom: 0.5rem; }

	&.double-line {
		line-height: normal;
		padding: 0.7rem 1rem 0.75rem; }

	span {
		display: block;
		font-size: 0.85rem; }

	&.btn-grey {
		background: $lightergrey;
		color: $black; }

	&.btn-smaller {
		font-size: 1.2rem;
		padding: 1rem 1rem;
		min-width: none;
		@include susy-breakpoint($medium) {
			font-size: 1.2rem;
			padding: .8rem 1.8rem; } } }

.text-green {
	color: $green; }

.question-block {
	margin: 1rem 0;
	border: 2px solid;
	padding: 0.5rem 1.5rem 0.7rem;
	cursor: pointer;

	.question {
		color: inherit;
		display: flex;
		justify-content: space-between;
		line-height: normal;
		font-style: italic;

		.question-title {
			flex: 1;
			padding: 0 1rem; } }

	.answer {
		color: $black;
		margin-top: 1rem;
		& > * {
			color: $black; } }

	.arrow {
		display: flex;
		align-items: center; }

	p:not(:last-child) {
		margin-bottom: 0; }

	&.closed {
		.arrow {
			transform: rotate(180deg); }
		.answer {
			display: none; } } }


.alert-bar {
	position: fixed;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: 99999;
	background: $black;
	color: $white;
	display: block;
	padding: 1rem;
	text-align: center;
	text-decoration: none;
	text-transform: uppercase;
	line-height: normal; }


.two-col {
	@include susy-breakpoint(800px) {
		display: flex;
		flex-wrap: wrap; }

	.col-item {
		padding-bottom: 2rem;
		@include susy-breakpoint(800px) {
			flex-basis: 50%;
			padding-right: 1rem;

			&:nth-child(even) {
				padding-right: 0;
				padding-left: 1rem; } } } }

.wallpaper_title {
	text-align: center;
	font-size: smaller;
	font-style: italic; }

.three-button-group {
	margin-top: 1rem;
	display: grid;
	grid-template-columns: repeat(3, minmax(0, 1fr));
	grid-gap: 1rem;
	a.btn {
		min-width: 0; } }

.staging-banner {
	position: fixed;
	z-index: 999999999999;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	background: red;
	color: white;
	padding: 0.5rem;
	text-align: center; }

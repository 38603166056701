footer {
	background: $black;
	padding: 2rem 2rem 3rem;
	color: $white;
	font-size: 1rem;
	line-height: 1.8;

	@include susy-breakpoint($small) {
		text-align: left;
		padding: 4rem 2rem 8rem; }

	@include susy-breakpoint($large) {
		font-size: 1.2rem;
		padding: 4rem 2rem; }

	ul.nav {
		display: none;
		font-size: 1rem;

		@include susy-breakpoint($small) {
			display: block; }

		@include susy-breakpoint($large) {
			font-size: 1.5rem; } }

	.inner {
		@include container(1400px); }

	.col {
		@include span(1 of 1);
		@include susy-breakpoint($small) {
			@include span(1 of 3 $splitgutter); } }

	.footer-logo {
		max-width: 130px;
		margin: 0 0 2rem 0;
		padding: 0.5rem 0;

		@include susy-breakpoint($small) {
			max-width: 200px;
			margin: 0; } }

	.footer-ey-logo {
		max-width: 130px;
		margin: 0 0 2rem 0;
		padding: 0.5rem 0;

		@include susy-breakpoint($small) {
			max-width: 150px;
			margin: 1rem 0; }

		@include susy-breakpoint($large) {
			max-width: 200px; } }

	ul {
		margin: 0 0 2rem;
		padding: 0;
		list-style-type: none;

		@include susy-breakpoint($small) {
			margin: 0; }

		li {
			padding: 0;
			margin: 0; } }

	ul.social-icons {
		margin: 0;
		li {
			display: inline-block;
			padding: 1rem 1.5rem 1rem 0; }

		i {
			font-size: 2.2rem; } }

	a {
		color: $white;
		text-decoration: none; }


	a.current-locale {
		cursor: pointer;

		img {
			max-width: 25px;
			margin-right: 0.75rem;
			display: inline-block;
			vertical-align: text-bottom;

			@include susy-breakpoint($small) {
				max-width: 30px;
				margin-right: 0.75rem;
				vertical-align: text-bottom; } } } }

//home page css - create one for each page and include to keep shit organised.

section.intro {
	position: relative;
	min-height: auto;

	.inner {
		min-height: 80vh;
		@include susy-breakpoint($small) {
			min-height: 100vh; } }

	.inner::before {
		display: none; }

	@include susy-breakpoint($small) {
		min-height: 100vh; }


	.slideshow {
		opacity: 0;
		transition: opacity 1s ease 1s;
		&.slick-initialized {
			opacity: 1; } }

	.slideshow,
	.slide {
		width: 100%;
		height: 35vh;
		@include susy-breakpoint($medium) {
			width: 50%;
			height: 100vh; } }


	.slogan-wrap {
		@include rkt('');
		z-index: 3;
		position: absolute;
		left: 0;
		top: 45vh;
		width: 100%;
		text-align: center;
		padding: 4rem 1rem;

		@include susy-breakpoint($small) {
			margin-top: 0px; }

		@include susy-breakpoint($medium) {
			transform: translateY(-50%);
			left: 50%;
			top: 50%;
			width: auto;
			margin-top: 0;
			padding-top: 0;
			padding: 0;
			margin-left: 100px; }

		@include susy-breakpoint($large) {
			margin-left: 130px; }

		.slogan {
			color: $darkergreen;
			line-height: 1;

			text-align: center;

			@include susy-breakpoint($small) {
				line-height: 1; }

			@include susy-breakpoint($medium) {
				padding: 0 2rem 1rem;
				text-align: left;
				font-size: 5rem; } }

		.sub-slogan {
			color: $black;
			font-size: 1.2rem;
			margin: 1rem 0 0;
			text-align: center;
			letter-spacing: normal;
			text-transform: uppercase;

			@include susy-breakpoint($small) {
				font-size: 1.7rem; }

			@include susy-breakpoint($medium) {
				padding: 0 2rem;
				text-align: left; } }

		a.btn.mobile-free-trial-btn {
			margin-top: 2rem;
			@include susy-breakpoint($medium) {
				display: none; } } }

	&::after {
		content: "";
		position: absolute;
		width: 100%;
		height: 45vh;
		background: rgba($white,0.8);
		top: 35vh;
		left: 0;
		bottom: 0;
		z-index: 1;

		@include susy-breakpoint($small) {
			height: 50%;
			top: 50%; }

		@include susy-breakpoint($medium) {
			width: 50%;
			height: 100%;
			right: 0;
			top: 0;
			left: auto; } } }

section.how-it-works {
	background: $lightgrey;

	.inner {
		min-height: auto; }

	.how-icons {
		visibility: hidden;
		padding: 2rem 0 0;
		@include container(100%);

		@include susy-breakpoint($small) {
			padding: 5rem 0 0; }

		&.slick-initialized {
			visibility: visible; }

		.icon {
			text-align: center;
			padding: 1rem 0 0;
			width: 100%;

			@include susy-breakpoint($medium) {
				padding: 0;
				@include span(1 of 3 $splitgutter); } }

		img {
			height: 200px;
			width: 200px;
			margin: 0 auto; }

		h4 {
			color: $purple;
			margin: 1.5rem 0 0; }

		p {
			font-size: 1.5rem;
			margin-top: 0.5rem; } }

	.material-icons {
		display: block;
		text-align: center;
		font-size: 3rem;
		margin-top: 0;
		margin-bottom: 2rem;
		@include susy-breakpoint($small) {
			display: none; } } }

section.features-benefits {

	.fb-row {
		position: relative;
		@include container(100%);
		margin-top: 4rem;

		@include susy-breakpoint($small) {
			margin-top: 4rem; } }

	.left {
		@include span(1 of 1);
		@include susy-breakpoint($medium) {
			@include span(1 of 2 $smallgutter first); } }


	.right {
		position: relative;
		@include span(1 of 1);
		@include susy-breakpoint($medium) {
			@include span(1 of 2 $smallgutter last); } }


	.fb {
		margin-bottom: 1rem;
		@include container(100%);
		@include susy-breakpoint($small) {
			margin-bottom: 0; }
		@include susy-breakpoint($medium) {
			display: table;
			padding: 1rem 0; } }

	.fb-inner {
		padding: 0 1rem; }

	.fb-slideshow {
		max-width: 400px;
		margin: 0 auto;
		margin-bottom: 2rem;
		@include rkt('');
		@include susy-breakpoint($medium) {
			@include rkt('match-from');
			margin-bottom: 0;
			margin-right: 0;
			max-width: none; }

		.slick-list,
		.slick-track,
		.slide {
			height: 100%; }

		.slide {

			img {
				width: 100%;
				margin: 0 auto; }

			@include susy-breakpoint($medium) {

				img {
					width: auto;
					height: 100%;
					margin: 0 auto; } } } }

	.icon {
		@include span(1 of 1);
		@include susy-breakpoint($medium) {
			@include span(1 of 5 first);
			position: relative;
			float: none;
			display: table-cell;
			vertical-align: middle; }

		img {
			max-width: 80px;
			margin: 0 auto;
			padding: 0.5rem 0;
			@include susy-breakpoint($medium) {
				max-width: 150px;
				padding: 0.5rem 1rem 0 0; } } }

	.content {
		text-align: center;
		@include span(1 of 1);
		@include susy-breakpoint($medium) {
			@include span(4 of 5 last);
			float: none;
			text-align: left;
			display: table-cell; }

		h4 {
			margin-top: 0;
			margin-bottom: 0;
			color: $blue;

			@include susy-breakpoint($small) {
				margin-top: 1rem; } }

		p {
			margin-top: 0.5rem;
			font-size: 1.5rem;
			padding: 0 3rem;

			@include susy-breakpoint($small) {
				padding: 0; } } } }

section.testimonials {
	background: $lightgrey;

	.inner {
		position: relative;
		@include susy-breakpoint($medium) {
			padding: 8rem; }

		&::before {
			display: none; } }

	.testimonials-slider {
		@apply mt-8 {}
		.slide {
			@apply bg-white p-8 mx-4 {}
			font-size: 1.2rem;
			width: 300px;

			.testimonial-name {
				@apply text-blue font-bold mb-0 {} }

			.testimonial-location {
				@apply uppercase font-bold mt-0 {} }

			.testimonial-content {
				@apply font-light {} } }

		i {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			font-size: 6rem;
			z-index: 999;
			color: $black;
			cursor: pointer;
			visibility: hidden;

			@include susy-breakpoint($small) {
				visibility: visible; }

			&.slick-prev {
				left: 2rem;
				@include susy-breakpoint($medium) {
					left: -6rem; } }

			&.slick-next {
				right: 2rem;
				@include susy-breakpoint($medium) {
					right: -6rem; } } } }

	.trustpilot-widget {
		@apply mt-8 {}
		@screen md {
			@apply mt-24 {} } } }

section.free-trial {
	background-position: center;
	background-size: cover;
	min-height: 100vh;

	.inner {
		position: relative;
		padding-top: 9rem;
		@include susy-breakpoint($medium) {
			padding: 8rem; }

		&::before {
			display: none; } }

	.card-title {
		text-align: left; }

	.thankyou {
		p {
			color: $green; } } }


section.gallery {
	min-height: auto;
	background: $black;
	color: $white;

	.inner {
		padding-top: 4rem;
		padding-bottom: 2rem;
		@include susy-breakpoint($small) {
			padding-top: 7rem;
			padding-bottom: 4rem; } }

	.section-title {
		border-bottom: none;
		padding-left: 0;
		font-size: 1.6rem;
		text-align: center;
		display: block;

		@include susy-breakpoint($small) {
			font-size: 3rem; }

		&::after {
			display: none; }

		a {
			color: $white;
			text-decoration: none; } }

	.images {
		margin: 4rem 0 0;
		position: relative;

		@include susy-breakpoint($small) {

			&::before {
				position: absolute;
				width: 20%;
				height: 100%;
				content: "";
				top: 0;
				left: 0;
				background: linear-gradient(to right, $black, rgba($black, 0));
				z-index: 998; }

			&::after {
				position: absolute;
				width: 20%;
				height: 100%;
				content: "";
				top: 0;
				right: 0;
				background: linear-gradient(to left, $black, rgba($black, 0));
				z-index: 998; } }


		i {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			font-size: 6rem;
			z-index: 999;
			color: $white;
			cursor: pointer;
			visibility: hidden;

			@include susy-breakpoint($small) {
				visibility: visible; }

			&.slick-prev {
				left: 2rem; }

			&.slick-next {
				right: 2rem; } }

		.slide {
			vertical-align: middle;

			img {
				height: 250px;
				width: auto;
				margin: 0 1rem;
				@include susy-breakpoint($small) {
					height: 350px; } } } }

	.material-icons {
		display: block;
		text-align: center;
		font-size: 3rem;
		margin-top: 1rem;
		margin-bottom: 2rem;
		@include susy-breakpoint($small) {
			display: none; } } }

section.about {

	&::before {
		display: none; }

	.section-title {
		padding-left: 0;
		display: block;
		text-align: left; }

	.about-left {
		background-size: contain;
		background-position: center right;
		background-repeat: no-repeat;
		@include span(1 of 1);
		@include susy-breakpoint($medium) {
			@include span(isolate 2 at 1 of 6 $smallgutter first);
			@include rkt('match'); } }

	.about-right {
		padding: 0 1rem;
		@include span(1 of 1) {
			@include susy-breakpoint($medium) {
				padding: 0 2rem 0 0;
				@include span(isolate 3 at 3.5 of 6 $smallgutter last);
				@include rkt('match'); } } } }


section.free-trial-feature {
	.inner {
		padding: 4rem 2rem; }

	.copy {
		@include container(1000px);
		text-align: center; }

	h3 {
		font-size: 2rem; } }

section.world-map {

	.inner {
		padding: 4rem 1rem 0; }

	.world-stats {
		@include container(100%);
		text-align: center;
		display: inline-table;
		width: 100%;

		.stat {
			text-align: center;
			margin: 1rem 0;
			@include span(1 of 2 $splitgutter);
			float: none;
			display: inline-block;
			@include susy-breakpoint($small) {
				@include span(1 of 2 $splitgutter) {
					float: none;
					display: inline-block; } }
			@include susy-breakpoint($medium) {
				@include span(1 of 3 $splitgutter) {
					float: none;
					display: inline-block; } }
			@include susy-breakpoint($large) {
				@include span(1 of 5 $splitgutter) {
					float: none;
					display: inline-block; } }

			&-number {
				font-size: 2.8rem;
				@include susy-breakpoint($small) {
					font-size: 3rem; } }

			&:nth-child(1) .stat-number {
				color: $purple; }

			&:nth-child(2) .stat-number {
				color: $orange; }

			&:nth-child(3) .stat-number {
				color: $blue; }

			&:nth-child(4) .stat-number {
				color: $yellow; }

			&:nth-child(5) .stat-number {
				color: $green; }

			&-title {
				text-transform: uppercase;
				font-size: 1rem;
				@include susy-breakpoint($small) {
					font-size: 1.5rem; } } } }

	.world-stats-map {
		padding: 2rem 0; } }

section.franchise {
	background-position: center;
	background-size: cover;
	.inner {
		position: relative; }

	.card-title {
		color: $blue;
		text-align: left; }

	.franchise-form-intro {
		color: $blue;

		p {
			margin-bottom: 0; } }

	form {
		margin-top: 1rem;
		button {
			background: $blue; }

		.thankyou {
			p {
				color: $blue; } } }

	a {
		color: $black;
		background: none;
		padding: 0;
		text-decoration: underline; } }


section.our-customers {
	.customer-logos {
		position: relative;
		img {
			max-width: 200px;
			padding: 2rem; }

		i {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			font-size: 6rem;
			z-index: 999;
			color: $black;
			cursor: pointer;
			visibility: hidden;

			@include susy-breakpoint($small) {
				visibility: visible; }

			&.slick-prev {
				left: 2rem; }

			&.slick-next {
				right: 2rem; } }

		@include susy-breakpoint($small) {

			&::before {
				position: absolute;
				width: 20%;
				height: 100%;
				content: "";
				top: 0;
				left: 0;
				background: linear-gradient(to right, $white, rgba($white, 0));
				z-index: 998; }

			&::after {
				position: absolute;
				width: 20%;
				height: 100%;
				content: "";
				top: 0;
				right: 0;
				background: linear-gradient(to left, $white, rgba($white, 0));
				z-index: 998; } } } }

.landingModal {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: rgba(white, 1);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 999999;
	opacity: 0;
	transition: opacity 0.3s ease;
	pointer-events: none;
	background-size: cover;
	background-position: center;

	.at-work-bg,
	.at-home-bg {
		position: absolute;
		top: 0;
		bottom: 0;
		width: 50%; }

	.at-work-bg {
		left: 0; }

	.at-home-bg {
		right: 0; }

	&.open {
		opacity: 1;
		pointer-events: all; }

	.landing-wrapper {
		display: flex;
		flex-direction: column;
		background: rgba($white, 0.4);
		padding: 0.5rem;
		@include susy-breakpoint($medium) {
			padding: 1rem;
			flex-direction: row; } }

	.button,
	.logo {
		width: 160px;
		height: 160px;
		margin: 0.5rem;
		@include susy-breakpoint($medium) {
			margin: 1rem;
			width: 200px;
			height: 200px; }
		@include susy-breakpoint($large) {
			width: 260px;
			height: 260px; } }

	// .logo
	// 	display: none
	// 	+susy-breakpoint($medium)
	// 		display: block

	.button {
		@apply no-underline {}
		color: $white;
		background-size: cover;
		display: flex;
		align-items: center;
		cursor: pointer; }

	.button-label {
		@apply flex justify-between text-center flex-col {}
		width: 100%;
		font-size: 1.5rem;
		padding: 0.5rem 1rem;
		text-transform: uppercase;

		div {
			@apply py-1 {} } } }

#at-home,
#order-today,
#offer {
	.branding-bar .sub-slogan,
	section.how-it-works .how-icons h4 {
		color: $athomepurple; }

	.free-trial-btn,
	#order-today-form .submit button,
	.mobile-free-trial-btn.btn {
		background-color: $athomepurple; }

	.free-trial-btn {
		position: fixed;
		display: block;
		bottom: 0;
		left: 0;
		right: 0;
		@include susy-breakpoint($medium) {
			bottom: auto;
			left: auto;
			right: 13rem; } }

	.page {
		min-height: 50vh;

		a:not(.btn) {
			@apply text-athomepurple {} } }

	section#order-today .inner {
		padding-top: 9rem;
		@include susy-breakpoint($medium) {
			padding-top: 8rem; } }

	#order-today-form {
		label {
			padding: 0 0 0.5rem;
			display: block;
			font-size: 1.2rem; }

		.order-fields {
			@screen sm {
				@apply grid grid-cols-3 gap-4 {} }
			.order-field {
				@apply mb-4 relative {}
				@apply p-4 cursor-pointer {}
				&:hover .field-state {
					box-shadow: inset 0 0 0 2px $athomepurple; }
				.field-state {
					@apply absolute inset-0 pointer-events-none {}
					box-shadow: inset 0 0 0 2px $lightergrey; }
				img,
				.order-field-label {
					@apply relative z-20 {} }

				img {
					@apply pb-4 {} }

				& > input:checked {
					& + .field-state {
						@apply bg-athomepurple {}
						box-shadow: inset 0 0 0 2px $athomepurple; }
					& ~ .order-field-label {
						@apply text-white {} } }

				&.parsley-error {
					.field-state {
						box-shadow: inset 0 0 0 2px red; } }

				.order-field-label {
					@apply font-bold block text-center {}
					font-size: 1rem; } } }


		#formFieldsWrapper {
			transition: opacity 0.3s ease;

			input,
			select {
				transition: opacity 0.3s ease; }

			&.wait {
				@apply opacity-50 {} }

			&.locked {
				@apply pointer-events-none {}
				.field {
					@apply bg-transparent cursor-not-allowed opacity-50 p-0 {} }
				.field::after,
				.required-note {
					@apply hidden {} } } }

		#paymentWrapper {
			transition: opacity 0.3s ease;
			&.wait {
				@apply opacity-50 {} }


			.payment-field-labels {
				@screen md {
					@apply flex justify-between items-center {} } } } }



	section.intro {
		min-height: 100vh;

		&:after {
			display: none; } }

	#bgvid {
		object-fit: cover;
		width: 100%;
		height: 100vh;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 99; }

	.scroll-indicator {
		position: absolute;
		bottom: 6rem;
		left: 50%;
		transform: translateX(-50%);
		z-index: 999;
		@include susy-breakpoint($medium) {
			bottom: 2rem; }
		i {
			color: white;
			font-size: 1.5rem;
			@include susy-breakpoint($medium) {

				font-size: 2rem; } } }

	.at-home-logo {
		position: absolute;
		top: 0;
		left: 0;
		width: 200px;
		height: 200px;
		z-index: 999;
		padding: 1.5rem;
		@include susy-breakpoint($small) {
			padding: 2rem;
			width: 280px;
			height: 280px; }
		@include susy-breakpoint($medium) {
			padding: 3rem;
			width: 400px;
			height: 400px; }


		svg {
			position: absolute;
			top: 0;
			width: 200px;
			height: 200px;
			left: 0;
			z-index: 1;
			@include susy-breakpoint($small) {
				width: 280px;
				height: 280px; }
			@include susy-breakpoint($medium) {
				width: 400px;
				height: 400px; }
			polygon {
				fill: black; } }

		img {
			position: relative;
			z-index: 2;
			max-width: 90px;
			@include susy-breakpoint($small) {
				max-width: 120px; }
			@include susy-breakpoint($medium) {
				max-width: 200px; } }

		p {
			position: relative;
			z-index: 2;
			color: $athomepurple;
			text-transform: uppercase;
			font-size: 1rem;
			margin: 0.5rem 0;
			@include susy-breakpoint($small) {
				font-size: 1.3rem; }
			@include susy-breakpoint($medium) {
				margin: 1rem 0;
				font-size: 1.6rem; } } }


	section.testimonials {
		.testimonial-name {
			@apply text-green {} } } }
